<div class="coming-wrapper">
    <div class="row">
        <div class="col-xs-10 col-md-10 col-lg-9 col-xl-5 mx-auto">
            <div class="coming-soon-wrapper">
                <div class="card">
                    <div class="card-header bg-primary">
                        <h4 class="text-uppercase">Under maintenance</h4>
                    </div>
                    <div class="card-body">
                        <p class="mb-2">We are under maintenance right now. We'll be back soon.</p>
                        <form action="javascript:void(0)" method="post">
                            <div class="row">
                                <div class="col-lg-11 mx-auto">
                                    <p class="mb-2 text-muted">Searching for something?</p>
                                    <div class="form-group">
                                        <div class="input-group">
                                            <span class="input-group-btn">
                                                <button class="btn btn-primary" type="button">
                                                    <i class="fa fa-search"></i>
                                                </button>
                                            </span>
                                            <input class="form-control" placeholder="Search" type="text">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-danger btn-square btn-lg">Submit</button>
                                    </div>
                                    <div class="links">
                                        <p class="text-muted">Already have an account?
                                            <a routerLink="/session/loginone" class="text-primary">Sign in here</a>
                                        </p>
                                        <p class="text-muted">New user?
                                            <a routerLink="/session/register" class="text-primary">Sign up here</a>
                                        </p>
                                    </div>
                                    <!-- links closed -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- Card Closed -->
            </div>
            <!-- Coming Soon Wrapper closed -->
        </div>
    </div>
</div>
<!-- Coming wrapper closed -->