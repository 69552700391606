<div class="loginone-wrapper">
   
      
    <div class="login-content text-center">
        <div class="login-inner-wrapper-2">
            <div class="mb-2">
                <span class="square-100">
                  <img src="../../logo/navituslogowithoutfont.png" width="70px" height="70px" />
                  </span>
            </div>
            <div class="mb-3">
                <p class="text-primary"><b style="font-size:20px;">navitusSchool</b></p>
                <!-- <h2 class="text-muted">Sign into your pages account</h2> -->
            </div>
            <!-- <form *ngIf="Otp" class="login-form" [formGroup]="form"  (ngSubmit)="SendOtp(form.value)">
                
                
                      <div class="form-group" >
                    <input type="tel" pattern="[0-9]*"  formControlName="otp" class="form-control form-control-lg input-square" placeholder="Enter OTP" required>
                </div>
                <div class="font12 mt3 mb25" style="text-align: center;">
                    <a style="color: #5A5A5A;" (click)="r1()">Didn't get OTP? Resend <span *ngIf="workingtimeout">(Wait {{remaining}} secs)</span></a>
                  </div>
                 <br>
               
           
  
                
  
                <div class="mb-3">
                    <button type="submit" [disabled]="!form.valid" class="btn btn-primary btn-block btn-lg btn-square">Verify OTP</button>
                </div>
                <div class="login-meta mb-3">
                    <div class="row">
                     
                        <div class="col-xs-8 col-sm-8 col-md-8 text-right" style="margin-left: 16px;" > 
                            <p  class="text-muted">Back to Login? <a href="javascript:void(0)" (click)="Login()">Click Here</a></p> 
                        </div>
                    </div>
                </div>
                <div class="login-social-icon">
                    <ul class="list-inline">
                       <li class="list-inline-item"><a href="javascript:void(0)"><i class=""></i>Copyright NEPL © 2019-2020</a></li>
                      
                    </ul>
                </div>
            </form> -->

            <!-- chnage Password form fill -->

            <form  class="login-form" [formGroup]="form1"  (ngSubmit)="Changepassword(form1.value)">
                
                
          <div class="form-group">
              <input   formControlName="password" class="form-control form-control-lg input-square" placeholder="Enter Password" [type]="showPassword ? 'text' : 'password'" required>
              <i style="float:right;margin-top: -26px;
              margin-right: 10px;"  alt="show" class="fa fa-eye" 
              (click)="showPassword = !showPassword"
              [class.hide]="showPassword"></i> 
              <i style="float:right;margin-top: -26px;
              margin-right: 10px;" alt="hide" class="fa fa-eye-slash" 
              (click)="showPassword = !showPassword"
              [class.hide]="!showPassword"></i>
          </div>
         
          <div class="form-group">
            <input   formControlName="cpassword" class="form-control form-control-lg input-square" placeholder="Confirm New Password" [type]="showPassword1 ? 'text' : 'password'" required>
            <i style="float:right;margin-top: -26px;
            margin-right: 10px;"  alt="show" class="fa fa-eye" 
            (click)="showPassword1 = !showPassword1"
            [class.hide]="showPassword1"></i>
            <i style="float:right;margin-top: -26px;
            margin-right: 10px;" alt="hide" class="fa fa-eye-slash" 
            (click)="showPassword1 = !showPassword1"
            [class.hide]="!showPassword1"></i>
        </div>
       
         
          <!-- <div class="login-meta mb-3">
              <div class="row">
               
                  <div class="col-xs-6 col-sm-6 col-md-6 text-right" > 
                      <a (click)="Login()" class="text-muted">Login?</a> 
                  </div>
              </div>
          </div> -->

          

          <div class="mb-3">
              <button type="submit" [disabled]="!form1.valid" class="btn btn-primary btn-block btn-lg btn-square">Reset Password</button>
          </div>
          <div class="login-meta mb-3">
            <div class="row">
             
                <div class="col-xs-8 col-sm-8 col-md-8 text-right" style="margin-left: 16px;" > 
                    <p  class="text-muted">Back to Login? <a href="javascript:void(0)" (click)="Login()">Click Here</a></p> 
                </div>
            </div>
        </div>
          <div class="login-social-icon">
              <ul class="list-inline">
                 <li class="list-inline-item"><a href="javascript:void(0)"><i class=""></i>Copyright NEPL © 2019-2020</a></li>
                
              </ul>
          </div>
      </form>
            <!-- change Password form fill  -->
            <!-- login form closed -->
      
        <!-- login inner wrapper closed -->
  
    <!-- login content closed -->
  </div>
  <div id="rightdiv" class="col-md-offset-6 col-md-3" style="
              /* font-size:16px; */
               opacity: 0.8;
               position: fixed;
               right: 0;
               bottom: 0;
               /* border-radius: 10px; */
               color: #ff671e;
               /* background-color: lightgray !important; */
               /* padding: 5px; */
               /*margin-top: 1.0em;*/
               text-align: center;
               ">
                  <div><br></div>
                  <b id="con">Powered By navitusSchool</b>
                  <br>
                  <a href="https://navituseducation.com/">Navitus Education Pvt.Ltd.</a>
                  <div><br></div>
              </div>
  <!-- login form wrapper closed -->
  </div>
  <!-- login-one- wrapper closed -->
  </div>
  
  
  
  
  
  
  
  