<div class="login-wrapper">
	<div class="login-form-wrapper">
		<div class="row">
			<div class="col-sm-10 col-md-8 col-lg-8 col-xl-5 mx-auto">
				<div class="login-block text-center">
					<div class="login-icon mb-5">
						<span class="square-100"><i class="fa fa-unlock-alt"></i></span>
					</div>
					<div class="pos-relative d-inline-block mb-5">
						<span class="badge badge-success badge-circle status-top-right fa-notify"><i class="fa fa-unlock-alt"></i></span>
						<span>
							<img src="assets/img/user-1.jpg" class="img-fluid rounded-circle d-inline-block mb-3" alt="admin thumb" width="150" height="150" />
						</span>
						<p>Anisha Willams</p>
					</div>
					<form class="login-form" action="javascript:void(0)" method="post">
						<div class="form-group">
							<input type="password" name="password" class="form-control form-control-lg input-square" placeholder="Password" required>
						</div>
						<div class="mb-5">
							<a routerLink="/dashboard/dashboard-v1" class="btn btn-primary btn-block btn-square btn-lg">Unlock</a>
						</div>
						<span>Not you? <a routerLink="/session/loginone" class="text-muted">Sign in as different user</a></span>
					</form>
					<!-- login form closed -->
				</div>
				<!-- login block closed -->
			</div>
		</div>
	</div>
	<!-- login form wrapper closed -->
</div>
<!-- login wrapper closed -->
