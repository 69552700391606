<div class="coming-wrapper">
    <div class="row">
        <div class="col-xs-10 col-md-10 col-lg-9 col-xl-5 mx-auto">
            <div class="coming-soon-wrapper">
                <div class="card">
                    <div class="card-header bg-primary">
                        <h4 class="text-uppercase">Subscribe</h4>
                    </div>
                    <div class="card-body">
                        <form action="javascript:void(0)" method="post">
                            <div class="row">
                                <div class="col-lg-9 mx-auto">
                                   <p class="mb-3 text-muted">Subscribe to our newsletter to receive weekly updates.</p>
                                    <div class="form-group">
                                       <label>Your Email</label>
                                        <div class="input-group">
                                            <span class="input-group-addon" id="basic-addon1"><i class="fa fa-envelope m-0"></i></span>
                                            <input type="text" class="form-control" placeholder="Username" aria-describedby="basic-addon1">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-danger btn-square btn-lg">Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- card body closed -->
                </div>
                <!-- Card closed -->
            </div>
            <!-- Coming soon wrapper closed -->
        </div>
    </div>
</div>
<!-- Coming wrapper closed -->