<div class="loginone-wrapper">
   
      
  <div class="login-content text-center">
      <div class="login-inner-wrapper-2">
          <div class="mb-2">
              <span class="square-100">
                <img src="../../logo/navituslogowithoutfont.png" width="70px" height="70px" />
                </span>
          </div>
          <div class="mb-3">
              <p class="text-primary"><b style="font-size:20px;">navitusSchool</b></p>
              <!-- <h2 class="text-muted">Sign into your pages account</h2> -->
          </div>
          <form class="login-form" [formGroup]="form"  (ngSubmit)="doLogin(form.value)">
              
                <!-- <input type="text"  formControlName="type" name="type"   value="web"> -->
                <input type="hidden" class="form-control" formControlName="type" [(ngModel)]="type"
                value="{{type}}">

                <div class="form-group" >
                    <!-- <div class="col-12 col-md-8 col-xl-6 " > -->
                        <ng-multiselect-dropdown 
                        [data]="loginRole" 
                        [placeholder]="'Select Role'"
                        [settings]="dropdownSettings"
                        (onSelect)="onItemSelect($event)" 
                        (onDeSelect)="onItemDeSelect($event)"
                        >
                        </ng-multiselect-dropdown>
                      <!-- </div>      -->
                </div>

               
                <div class="form-group" *ngIf="selectSchool">
                      
                            <ng-multiselect-dropdown 
                            [data]="loginSchool" 
                            [placeholder]="'Select school'"
                            [settings]="dropdownSettings2"
                            (onSelect)="onItemSelect2($event)" 
                            (onDeSelect)="onItemDeSelect2($event)"
                            >
                            </ng-multiselect-dropdown>
                         
                    </div>
             

              <div class="form-group" *ngIf="showhideDiv">
                  <input type="text" name="username" [(ngModel)]="username" formControlName="username" class="form-control form-control-lg input-square" placeholder="Email Address/Username/Student ID" required>
              </div>
              <div class="form-group" *ngIf="showhideDiv">
                  <input type="password" name="password"  [(ngModel)]="password" formControlName="password" class="form-control form-control-lg input-square" placeholder="Password" required>
              </div>
              <p *ngIf="!authenticationFlag" class="abc">{{message}}</p>
              <div class="login-meta mb-3">
                  <div class="row">
                      <div class="col-xs-6 col-sm-6 col-md-6">
                          <!-- <label class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0 d-flex">
                              <input class="custom-control-input" type="checkbox">
                              <span class="custom-control-indicator"></span>
                              <span class="custom-control-description">Remember Me</span>
                          </label> -->
                      </div>
                      <div class="col-xs-6 col-sm-6 col-md-6 text-right" *ngIf="showhideDiv"> 
                          <a (click)="forgotpassword()" class="text-muted">Forgot Password?</a> 
                      </div>
                  </div>
              </div>

              

              <div class="mb-3">
                  <button type="submit" [disabled]="!form.valid" class="btn btn-primary btn-block btn-lg btn-square">Sign In</button>
              </div>
              <!-- <div class="mb-3">
                  <a (click)="register()" class="text-muted">Still no account? Please go to Register</a>
              </div> -->
              <div class="login-social-icon">
                  <ul class="list-inline">
                     <li class="list-inline-item"><a href="javascript:void(0)"><i class=""></i>Copyright NEPL © 2019-2020</a></li>
                      <!-- <li class="list-inline-item"><a href="javascript:void(0)"><i class="fa fa-google-plus fa-lg text-warning"></i></a></li>
                      <li class="list-inline-item"><a href="javascript:void(0)"><i class="fa fa-twitter fa-lg text-info"></i></a></li>
                      <li class="list-inline-item"><a href="javascript:void(0)"><i class="fa fa-facebook-official fa-lg text-primary"></i></a></li>
                      <li class="list-inline-item"><a href="javascript:void(0)"><i class="fa fa-instagram fa-lg text-danger"></i></a></li> -->
                  </ul>
              </div>
          </form>
          <!-- login form closed -->
    
      <!-- login inner wrapper closed -->

  <!-- login content closed -->
</div>
<div id="rightdiv" class="col-md-offset-6 col-md-3" style="
            /* font-size:16px; */
             opacity: 0.8;
             position: fixed;
             right: 0;
             bottom: 0;
             /* border-radius: 10px; */
             color: #ff671e;
             /* background-color: lightgray !important; */
             /* padding: 5px; */
             /*margin-top: 1.0em;*/
             text-align: center;
             ">
                <div><br></div>
                <b id="con">Powered By navitusSchool</b>
                <br>
                <a href="https://navituseducation.com/">Navitus Education Pvt.Ltd.</a>
                <div><br></div>
            </div>
<!-- login form wrapper closed -->
</div>
<!-- login-one- wrapper closed -->
</div>







