<div class="coming-wrapper">
	<div class="row">
		<div class="col-sm-10 col-md-10 col-lg-10 mx-auto">
			<div class="coming-soon-wrapper text-center">
				<div class="mb-5">
					<h2 class="text-capitalize font-5x fw-bold">Coming Soon</h2>
				</div>
				<div class="getting-started font-3x mb-5"></div>
				<div class="mb-3">
					<h2 class="text-uppercase font-4x fw-bold">We're so close</h2> 
				</div>
				<p class="content font-lg">Enjoy a Very Limited Opportunity by subscribing to our newsletter.
				<br> Check out the time remaining on top left, hurry up!</p>
			</div>
			<!-- coming soon wrapper closed -->
		</div>
	</div>
	<!-- row closed -->
</div>
<!-- coming wrapper closed -->

