import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { MasterService } from './master.service';

@Injectable({
  providedIn: 'root'
})
export class LogintypeService {

  reqheadermain = new HttpHeaders({'Authorization':'Bearer '+this.token.getToken()});
  url= '';

  constructor( 
    public http: HttpClient,
    public token:TokenService,
    public masterService: MasterService
    ){ 
      this.url = masterService.url
    }

    loginType(value){
      // var value1 = { logintype : value};
      return this.http.post(this.url+'master_login_type',value,{headers:this.reqheadermain}); 
    }

    showType(){
      return this.http.get(this.url+'get_master_login_type',{headers:this.reqheadermain});
    }

}
