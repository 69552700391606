<div class="loginone-wrapper">
   
      
    <div class="login-content text-center">
        <div class="login-inner-wrapper-2">
            <div class="mb-2">
                <span class="square-100">
                  <img src="../../logo/navituslogowithoutfont.png" width="70px" height="70px" />
                  </span>
            </div>
            <div class="mb-3">
                <p class="text-primary"><b style="font-size:20px;">navitusStudyGuides</b></p>
                <!-- <h2 class="text-muted">Sign into your pages account</h2> -->
            </div>
            <form class="login-form" [formGroup]="form"  (ngSubmit)="forgotpassword(form.value)">
                
                
                      <div class="form-group" >
                    <input type="text"  formControlName="username" class="form-control form-control-lg input-square" placeholder="Enter Username" required>
                </div>

             
               
               
  
                
  
                <div class="mb-3"  *ngIf="show">
                    <button type="submit" [disabled]="!form.valid" class="btn btn-primary btn-block btn-lg btn-square">Submit</button>
                </div>

                <div *ngIf="hide" class="mb-3">
                    <button class="btn btn-primary btn-block btn-lg btn-square"  disabled>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              <span > Please wait...</span>
                    </button>
                 </div>
                <div class="login-meta mb-3">
                    <div class="row">
                     
                        <div class="col-xs-8 col-sm-8 col-md-8 text-right" style="margin-left: 16px;" > 
                            <p  class="text-muted">Back to Login? <a href="javascript:void(0)" (click)="Login()">Click Here</a></p> 
                        </div>
                    </div>
                </div>
                <div class="login-social-icon">
                    <ul class="list-inline">
                       <li class="list-inline-item"><a href="javascript:void(0)"><i class=""></i>Copyright NEPL © 2019-2020</a></li>
                      
                    </ul>
                </div>
            </form>
            <!-- login form closed -->
      
        <!-- login inner wrapper closed -->
  
    <!-- login content closed -->
  </div>
  <div id="rightdiv" class="col-md-offset-6 col-md-3" style="
              /* font-size:16px; */
               opacity: 0.8;
               position: fixed;
               right: 0;
               bottom: 0;
               /* border-radius: 10px; */
               color: #ff671e;
               /* background-color: lightgray !important; */
               /* padding: 5px; */
               /*margin-top: 1.0em;*/
               text-align: center;
               ">
                  <div><br></div>
                  <b id="con">Powered By navitusSchool</b>
                  <br>
                  <a href="https://navituseducation.com/">Navitus Education Pvt.Ltd.</a>
                  <div><br></div>
              </div>
  <!-- login form wrapper closed -->
  </div>
  <!-- login-one- wrapper closed -->
  </div>
  
  
  
  
  
  
  
  