import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
   selector: 'ms-auth',
   templateUrl:'./auth-component.html',
})
export class AuthComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
	
}



