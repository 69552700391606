import {Component, Optional, ViewEncapsulation} from '@angular/core';
import { TranslateService} from '@ngx-translate/core';
import { AuthService } from './auth.service';
import { Router,ActivatedRoute} from '@angular/router';
import { MasterService } from './services/master.service';

@Component({
  	selector: 'chankya-app',
  	template:'<router-outlet></router-outlet>',
    encapsulation: ViewEncapsulation.None
})
export class ChankyaAppComponent {
   public loggedIn:boolean;
   constructor(translate: TranslateService,
    public auth:AuthService,
    public route:Router,
    public activeroute:ActivatedRoute,
    public masterService: MasterService,
  ) {
      translate.addLangs(['en', 'fr']);
      translate.setDefaultLang('en');

      const browserLang: string = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

      this.auth.authStatus.subscribe(value => this.loggedIn = value);
        var url = masterService.imageurl + 'forgot-password';
       // var url2 = masterService.imageurl + 'confirm-password';
      console.log(window.location.href);
      var str= window.location.href;
      var splitted = str.split("/"); 
      console.log(splitted[5]);
      // console.log(this.loggedIn);
      if(!this.loggedIn && splitted[5] != 'forgot-password' && splitted[5] != 'confirm-password'){
         this.route.navigate(['/login'])

      }

     }

     
}
