<div class="login-wrapper">
   <div class="login-form-wrapper">
      <div class="row">
         <div class="col-sm-10 col-md-8 col-lg-8 col-xl-5 mx-auto">
            <div class="login-block text-center">
               <div class="login-icon mb-5">
                  <span class="square-100"><i class="fa fa-unlock-alt"></i></span>
               </div>
               <div class="mb-5">
                  <h2 class="text-capitalize font-2x mb-3">Recover Password</h2>
                  <p>Enter your email and we'll send you instructions on how to reset your password.</p>
               </div>
               <form class="login-form" action="javascript:void(0)" method="post">
                  <div class="form-group">
                     <input type="email" name="email" class="form-control form-control-lg input-square" placeholder="Your email" required>
                  </div>
                  <div class="mb-4">
                     <a href="index.php" class="btn btn-primary btn-block btn-square btn-lg">Send Me Password</a>
                  </div>
                  <div class="row">
                     <div class="col col-lg-6">
                        <a routerLink="/session/register" class="btn btn-danger btn-block btn-lg btn-pill">Register</a>
                     </div>
                     <div class="col col-lg-6">
                        <a routerLink="/session/loginone" class="btn btn-secondary btn-block btn-lg btn-pill">Login</a>
                     </div>
                  </div>
               </form>
               <!-- login form closed -->
            </div>
            <!-- login block closed -->
         </div>
      </div>
   </div>
   <!-- login form wrapper closed -->
</div>
<!-- login wrapper closed -->


