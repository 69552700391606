import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceapiService } from '../serviceapi.service';

import {Router} from "@angular/router";
import { TokenService } from 'app/services/token.service';
// import { AuthService } from 'app/auth.service';
import { AuthService } from 'app/auth.service';
import { RoleserviceService } from 'app/services/roleservice.service';
import { UserService } from 'app/services/user.service';
import { MasterService } from 'app/services/master.service';
import { ToastrMessageService } from 'app/services/toastr-message.service';
import { PartnerService } from 'app/services/partner.service';

@Component({
  selector: 'app-forget-password-change',
  templateUrl: './forget-password-change.component.html',
  styleUrls: ['./forget-password-change.component.scss']
})
export class ForgetPasswordChangeComponent implements OnInit {
  form: FormGroup;
  form1: FormGroup;
  Otp:boolean = true;
  ChnagePass:boolean = false;
  doneTypingInterval: number = 60000;
  public typingTimer;
  number: number;
  remaining: number;
  intervalvariable;
  workingtimeout: boolean;
  public showPassword: boolean;
  public showPassword1: boolean;
  public showPassword2: boolean;
  
  public username;  
  constructor( public PartnerService :PartnerService,
    public formBuilder: FormBuilder,
    public svcService: ServiceapiService,
    private token: TokenService,
    public router: Router,
    public auth:AuthService,
    public role:RoleserviceService,
    public userService: UserService,
    public masterService: MasterService,
    public toastr: ToastrMessageService,) { }

  ngOnInit(): void {
    this.form1 = this.formBuilder.group({
      cpassword: [null, [Validators.required]],
      password: [null, [Validators.required]],
    
  });
  this.form = this.formBuilder.group({
    otp: [null, [Validators.required]],
  
});
  }
  Login(){
    this.router.navigate(['login']);
  }
  // SendOtp(value){
  // value['username'] =  localStorage.getItem('username');
  // this.PartnerService.OtpVerfy(value).subscribe(data => {
  //   console.log(data);
  //   if(data['status_code'] == 403){
  //     return this.toastr.showError(data['data']);
  //    }
  //    if(data['status_code'] == 200){
  //     this.Otp = false;
  //     this.ChnagePass = true;
  //     return this.toastr.showSuccess(data['data']);
      
  //    }
  //  })

  // }

  Changepassword(value){
    console.log("password",)
     if(value['password'] != value['cpassword']){
      return this.toastr.showError('Password and Confirm Password must be same !');
     }
     else if(value['password'].length < 6  ){
      return this.toastr.showError('The password must be at least 6 characters !');
    }
    else if(value['password'].length > 16){
      return this.toastr.showError('The password must be  less 16 characters !');
    }
    
     else{
      value['firstname'] =  localStorage.getItem('firstname');
      value['lastname'] =  localStorage.getItem('lastname');
      value['birthDate'] =  localStorage.getItem('birthDate');
      value['mobile'] =  localStorage.getItem('mobile');
      value['role'] =  localStorage.getItem('role');



      this.PartnerService.ChangePass(value).subscribe(data => {
        console.log(data);
        if(data['status_code'] == 403){
          return this.toastr.showError(data['errors']['password']);
         }
        
         if(data['status_code'] == 200){
          localStorage.removeItem('firstname');
          localStorage.removeItem('lastname');
          localStorage.removeItem('birthDate');
          localStorage.removeItem('mobile');
          
          this.router.navigate(['login']);
          return this.toastr.showSuccess("Password change successfully !");
          
         }
       })
     }
 
  }
  // resendOtp(){
  // this.username =   localStorage.getItem('username')
  //  let data = {'username':this.username};
  //   this.PartnerService.ForgotPassword(data).subscribe(data => {
  //     console.log(data);
   
  //     if(data['status_code'] == 200){
     
  //      this.router.navigate(['chnageforgotpassword']);
  //      return this.toastr.showSuccess('OTP sent successfully to register Email !');
  //     }
  //    })
  // }

  // startinterval(){

  //   this.workingtimeout = true;
  
  //   clearTimeout(this.typingTimer);
   
  //   this.typingTimer = setTimeout(()=>{ 
  //    // alert('called123');
    
  // this.workingtimeout = false;
  
  //     ///////////// tymer code
      
  //   }, this.doneTypingInterval);
  
  
  // ////////////////For displaying
  
  // this.remaining =  60;
  //  clearInterval(this.intervalvariable);
  //     this.intervalvariable =  setInterval( () =>{
  // this.remaining =  this.remaining -1; 
  // // console.log(this.remaining);
  // if( this.remaining == 0){
  //   clearInterval(this.intervalvariable);
  //   this.workingtimeout = false;
  
  // }
  
  //     },1000);
  
  // }
  
  
  
  
  r1(){
    
  
  // if(this.workingtimeout == true){
  
  //   console.log('timer true do nothing');
  
  // } 
  // else {
  //   this.form.reset();
  //   this.resendOtp();
  //   console.log('timer false hit api and start new timer');
  //   // this.isRefreshingnew = true;
   
  //   this.startinterval();
  
  
  // }
  
  
  
    }
}
