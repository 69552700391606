import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { MasterService } from 'app/services/master.service';
@Injectable({
    providedIn: 'root'
})
export class ServiceapiService {


//   api_url: any = 'http://192.168.1.237/api/';
  api_url: any = '';
  constructor(private http: HttpClient, public masterService: MasterService) { 
    this.api_url = masterService.url;
  }


    private setHeaders(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };
        return new HttpHeaders(headersConfig);
    }


    private formatErrors(resp: any) {

        return Observable.throw((undefined !== resp.error) ? resp.error : 'Error 500!');
    }

    /**
     * Use GET method globally
     * @param {string} path
     * @param {URLSearchParams} params
     * @returns {Observable<any>}
     */



    //  createFood(food) {
    //       let body = JSON.stringify(food);
    //        return this.http.post('/api/food/', body, httpOptions);
    //     }



    get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.http.get(`${this.api_url}${path}`, { headers: this.setHeaders(), params: params })

    }

    /**
     * Use PUT method globally
     * @param {string} path
     * @param {Object} body
     * @returns {Observable<any>}
     */
    put(path: string, body: Object = {}): Observable<any> {
        return this.http.put(
            `${this.api_url}${path}`,
            JSON.stringify(body),
            { headers: this.setHeaders() }
        )
            ;
    }

    /**
     * Use POST method globally
     * @param {string} path
     * @param {Object} body
     * @returns {Observable<any>}
     */
    post(path: string, body: Object = {}): Observable<any> {
        return this.http.post(
            `${this.api_url}${path}`,
            JSON.stringify(body),
            { headers: this.setHeaders() }
        )
            ;
    }

    /**
     * Use DELETE method globally
     * @param path
     * @returns {Observable<any>}
     */
    delete(path): Observable<any> {
        return this.http.delete(
            `${this.api_url}${path}`,
            { headers: this.setHeaders() }
        )
            ;
    }
}
