import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';

@Injectable({
  providedIn: 'root'
})
export class ToastrMessageService {

  

  constructor(public toastr: ToastrManager) { }

  showSuccess(msg) {
    this.toastr.successToastr(msg, 'Success!', {
      maxShown: (1)
    });  
  }

  showError(msg) {
    this.toastr.errorToastr(msg, 'Oops!', {
      maxShown: (50)
    });
  }

  showWarning(msg) {
      this.toastr.warningToastr(msg, 'Alert!');
  }

  showInfo(msg) {
      this.toastr.infoToastr(msg, 'Info');
  }

  showMultipleSuccess(msg) {
    this.toastr.successToastr(msg, 'Success!', {
    });  
  }

  showMultipleError(msg) {
    this.toastr.errorToastr(msg, 'Oops!', {
    });
  }

}
