<div class="coming-wrapper">
    <div class="row">
        <div class="col-sm-10 col-md-10 col-lg-10 mx-auto">
            <div class="coming-soon-wrapper text-center">
                <div class="mb-3">
                    <h2 class="text-capitalize font-5x fw-bold">404</h2>
                </div>
                <div class="mb-3">
                    <h2 class="font-2x fw-bold text-danger">Page not found</h2> 
                    <p class="font-lg">Sorry! There might be some error on this page...</p>
                </div>
                <div class="mb-3">
                    <a routerLink="/dashboard/dashboard-v1" class="btn btn-primary btn-large btn-pill">Go back to Home</a>
                </div>
                <div class="mb-5">
                    <p class="font-lg">Stay connected, Subscribe us...</p>
                </div>
                <form action="javascript:void(0)" method="post">
                    <div class="row">
                        <div class="col-lg-4 mx-auto">
                            <div class="form-group">
                                <div class="input-group">
                                    <input class="form-control" placeholder="your email address" type="text">
                                    <span class="input-group-btn">
                                        <button class="btn btn-primary" type="button">Subscribe</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <!-- Subscribe form closed -->
            </div>
            <!-- coming soon wrapper closed -->
        </div>
    </div>
</div>
<!-- Coming wrapper closed -->