import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RoleserviceService {
  isValid=true;
  constructor() { }

  setrole(role){
    //localStorage.setItem('role',role);
    

    for (let entry of role) {
      // console.log(entry.name); // 1, "string", false
     localStorage.setItem(entry.name,entry.name);
  }

    var systemadin = role.find(x=>x.name == "System Admin");
   

    if(systemadin != null){
     // console.log("systemadin");
      //console.log(systemadin.name);
     return localStorage.setItem('role',systemadin.name);
    }
    var partner = role.find(x=>x.name == "Partner");   
 
    if(partner != null){
      // console.log("systemadin");
       //console.log(systemadin.name);
      return localStorage.setItem('role',partner.name);
     }

    var instiituteadmin = role.find(x=>x.name == "Institute Admin");
   

    if(instiituteadmin != null){
     // console.log("systemadin");
      //console.log(systemadin.name);
     return localStorage.setItem('role',instiituteadmin.name);
    }

    var schooladmin = role.find(x=>x.name == "School Admin");
    
    if(schooladmin != null){
     // console.log("schooladmin");
     // console.log(schooladmin.name);
      return localStorage.setItem('role',schooladmin.name);
     }
     

     var principal = role.find(x=>x.name == "Principal");
    
     if(principal != null){
     //  console.log("principal");
      // console.log(principal.name);
       return localStorage.setItem('role',principal.name);
      }


      var teacher = role.find(x=>x.name == "Teacher");
    
      if(teacher != null){
      //  console.log("teacher");
      //  console.log(teacher.name);
        return localStorage.setItem('role',teacher.name);
       }

       var accountant = role.find(x=>x.name == "Accountant");

       if(accountant != null){
        //  console.log("teacher");
        //  console.log(teacher.name);
          return localStorage.setItem('role',accountant.name);
         }

//     for (let entry of role) {
//       // console.log(entry); // 1, "string", false
//       //Generated by typescript 1.8.10
// switch (entry) {
//    case "System Admin": {
//       console.log("System Admin");
//       localStorage.setItem('systemadmin','systemadmin');
//       break;
//    }
	
//    case "School Admin": {
//       console.log("School Admin");
//       localStorage.setItem('schooladmin','schooladmin');
//       break;
//    }
	
//    case "Principal": {
//       console.log("Principal");
//       localStorage.setItem('principal','principal');
//       break;
//    }
	
//    case "Teacher": {
//       console.log("Teacher");
//       localStorage.setItem('teacher','teacher');
//       break;
//    }
	
//    default: {
//       console.log("Invalid choice");
//       break;
//    }
// }
    
//   }

    // console.log(role[0].name);

  //  this.data = res.json().results;

  }
}
