import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { MasterService } from './master.service';


@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  url = '';
  State: any = [
    { id: 1, name: "Andhra Pradesh " },
    { id: 2, name: "Arunachal Pradesh" },
    { id: 3, name: "Assam" },
    { id: 4, name: "Bihar " },
    { id: 5, name: "Chhattisgarh" },
    { id: 6, name: "Goa	" },
    { id: 7, name: "Gujarat" },
    { id: 8, name: "Haryana" },
  
    { id: 9, name: "Himachal Pradesh" },
    { id: 10, name: "Karnataka" },
    { id: 11, name: "Kerala" },
    { id: 12, name: "Madhya Pradesh" },
    { id: 13, name: "Maharashtra" },
    { id:14, name: "Manipur" },
    { id: 15, name: "Meghalaya" },
    { id: 16, name: "Mizoram" },
    { id: 17, name: "Nagaland" },
    { id: 18, name: "Odisha" },
    { id: 19, name: "Punjab" },
    { id: 20, name: "Rajasthan" },
    { id: 21, name: "Sikkim" },
    { id: 22, name: "Tamil Nadu" },
    { id: 23, name: "Telangana" },
    { id: 24, name: "Tripura" },
    { id: 25, name: "Uttar Pradesh" },
    { id: 26, name: "Uttarakhand" },
    { id: 27, name: "West Bengal" },
  ]

  reqheadermain = new HttpHeaders({ 'Authorization': 'Bearer ' + this.token.getToken() });
  constructor(
    public http: HttpClient,
    public token: TokenService,
    public masterService: MasterService,
  ) { 
    this.url = masterService.url
  }
  partnerlist(offset, limit, search, orderdir, orderby) {
    var data = { offset: offset, limit: limit, search: search, orderdir: orderdir, orderby: orderby };
    return this.http.post(this.url+'getpartnerData', data, { headers: this.reqheadermain });
  }
  partnerReferrallist(offset, limit, search, orderdir, orderby) {
    var data = { offset: offset, limit: limit, search: search, orderdir: orderdir, orderby: orderby };
    return this.http.post(this.url+'getreferraldata', data, { headers: this.reqheadermain });
  }


  freeuserlist(offset, limit, search, orderdir, orderby) {
    var data = { offset: offset, limit: limit, search: search, orderdir: orderdir, orderby: orderby };
    return this.http.post(this.url+'getcomplementry', data, { headers: this.reqheadermain });
  }
  partnerDetails() {
    return this.http.get(this.url + 'partnerDetails', { headers: this.reqheadermain });
  }
  AddPartnerDetails(value) {
    // var data = { board_id :value.board_id, grade_id : value.grade_id, price: value.price };
    return this.http.post(this.url + 'addPartnerData' , value , { headers:this.reqheadermain } );
  }
  EditPartner(id) {
   
    return this.http.get(this.url+'editPartnerData/'+id,{ headers:this.reqheadermain } );
  }
  UpdateDetails(value) {
  
    return this.http.post(this.url + 'updatePartnerData' , value, { headers:this.reqheadermain });
  }

  chnagePassword(value) {
  
    return this.http.post(this.url + 'changepasswordPartner' , value, { headers:this.reqheadermain });
  }
  activeInactivePartner(id, status) {
    var data = { id: id, status: status };
    return this.http.post(this.url + 'activePartnerData', data, { headers: this.reqheadermain });
  }
  activeInactiveReferral(id, status) {
    var data = { id: id, status: status };
    return this.http.post(this.url + 'activeinactivereferral', data, { headers: this.reqheadermain });
  }

  getProfileData() {
    return this.http.get(this.url + 'getProfileData', { headers: this.reqheadermain });
  }
  ForgotPassword(value) {
    return this.http.post(this.url + 'forgot-password',value, { headers: this.reqheadermain });
  }
  OtpVerfy(value) {
    return this.http.post(this.url + 'verifyotp',value, { headers: this.reqheadermain });
  }
  ChangePass(value) {
    return this.http.post(this.url + 'forgotpasswordnew',value, { headers: this.reqheadermain });
  }

  updateAddress(value) {
    return this.http.post(this.url + 'updatePartnerprofileData',value, { headers: this.reqheadermain });
  }
  ReferralFees(value) {
    return this.http.post(this.url + 'referral_fees',value, { headers: this.reqheadermain });
  }
  ReferralStructure() {
    return this.http.get(this.url + 'referralfeestructure', { headers: this.reqheadermain });
  }
}
