import { Injectable } from '@angular/core';
import { CanActivate,Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(public _authservice:AuthService,public _router:Router){}

  canActivate(): boolean {
  if(localStorage.getItem('token')){
   // console.log(this._authservice.loggedin);
   // console.log("1");
      return true;
    }else{
   //   console.log("2");
      this._router.navigate(['/login'])
      return false;
    }
  }
}
