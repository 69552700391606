// import { Response } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams,HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { TokenService } from './token.service';
import { MasterService } from './master.service';
// import { Response, ResponseContentType } from '@angular/common';
// import{Response}
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  url = '';
  downloadUrl = '';
  public profileImage = '';
  // token = localStorage.getItem('token');
  reqheadermain = new HttpHeaders({'Authorization':'Bearer '+ localStorage.getItem('token'),'inst_id':'1'});

  constructor(
    public http: HttpClient,
    public token:TokenService,
    public masterService: MasterService
  ) { 
    this.url = masterService.url;
    this.downloadUrl = masterService.imageurl;
  }

  getRoles(){
    return this.http.get(this.url+'getroles',{headers:this.reqheadermain});
  }

  getinstitueRoles(){
    return this.http.get(this.url+'getinstrole',{headers:this.reqheadermain});
  }
  getSchools(){
    return this.http.get(this.url+'allinstitutionschool',{headers:this.reqheadermain});
  }
  getUser(offset, limit, orderby, orderdir, search?){
    var data = {offset: offset, limit:limit, search:search, orderby:orderby, orderdir:orderdir}; 
    return this.http.post(this.url+'tableusers',data,{headers:this.reqheadermain}); 
  }

  registerUser(value){ 
    return this.http.post(this.url+'doregister',value,{headers:this.reqheadermain});
  }

  editUser(id){
    return this.http.get(this.url+'getedituser/'+id,{headers:this.reqheadermain});
  }

  updateUser(value){
    return this.http.post(this.url+'updateuser',value,{headers:this.reqheadermain});
  }

  deleteUser(id){
    var data = {ids: id}; 
    return this.http.post(this.url+'deleteuser',data,{headers:this.reqheadermain});
  }

  getSchoolAdminData(){

    return this.http.get(this.url+'schooladmin',{headers:this.reqheadermain});
  }

  userinfo(){
    return this.http.post(this.url+'userinfo',{headers:this.reqheadermain});
  }

  getstudentteacher(){
    return this.http.get(this.url+'getstudentteacher',{headers:this.reqheadermain});
  }

  getTeachers(){
    return this.http.get(this.url+'getallinstteacher',{headers:this.reqheadermain});
  }

  getStudents(){
    return this.http.get(this.url+'getallinststudent',{headers:this.reqheadermain});
  }

  getGroups(teacher){
    // alert(teacher);
    return this.http.get(this.url+'getspecificteachergroup/'+ teacher,{headers:this.reqheadermain});
  }

  getActualGroups(teacher){
    return this.http.get(this.url+'getspecificteachergroupactual/'+ teacher,{headers:this.reqheadermain});
  }

  getEditGroups(id){
    return this.http.get(this.url+'geteditgroup/'+ id,{headers:this.reqheadermain});
  }

  getTeacherSubjects(t123, grp){
    return this.http.get(this.url+'teachersubjects/'+t123+'/'+grp, { headers:this.reqheadermain });
  }

  getStudentsData(sub){
    return this.http.get(this.url+'studentgroup/'+ sub, { headers:this.reqheadermain } );
  }

  getStudentGroups(student){
    // alert(teacher);
    return this.http.get(this.url+'getstudentgroup/'+ student,{headers:this.reqheadermain});
  }

  getSubjects(tid,gid){
    return this.http.get(this.url+'checksubjects/'+tid+'/'+gid,{headers:this.reqheadermain});
  }

  getGroupSubjects(gid,tid){
    // var data = {teacherid: tid}; 
    return this.http.get(this.url+'checkgroupsubjects/'+gid + '/' + tid,{headers:this.reqheadermain});
  }

  getGroupEditSubjects(id){
    return this.http.get(this.url+'checkgroupeditsubjects/'+id,{headers:this.reqheadermain});
  }

  assignUser(value, group){ 
    // alert(group);
    if(value.role == 'Student') {
      var val = { role:value.role, student: value.student, group: group };
      return this.http.post(this.url+'assigngrp',val,{headers:this.reqheadermain});
    } else {
      // alert(JSON.stringify(value.group));
      console.log('value111111111');
      console.log(value);
      
      var val2 = { 	role:value.role, teacher: value.teacher, group: group, subject: value.subject, classTeacher: value.classTeacher };
      return this.http.post(this.url+'assigngrp',val2,{headers:this.reqheadermain});
    
    }
    
    // alert(JSON.stringify(val));
    // return this.http.post(this.url+'assigngrp',val,{headers:this.reqheadermain});
  }

  getAssignedTeachers(offset,limit,orderby, orderdir,search){
    var data1 = {offset: offset, limit:limit, search:search, orderby:orderby, orderdir:orderdir}; 
    return this.http.post(this.url+'teachergroupdetails',data1,{headers:this.reqheadermain});
  }

  getAssignedStudents(offset,limit,orderby, orderdir,search){
    var data2 = {offset: offset, limit:limit, search:search, orderby:orderby, orderdir:orderdir}; 
    return this.http.post(this.url+'studentgroupdetails',data2,{headers:this.reqheadermain});
  }

  removeTeacher(selected){
    return this.http.post(this.url+'removeteacher',selected,{headers:this.reqheadermain});
  }

  removeStudent(selected){
    return this.http.post(this.url+'removestudent',selected,{headers:this.reqheadermain});
  }
  
  profile(){
    return this.http.get(this.url+'getprofile',{headers:this.reqheadermain});
  }

  updateprofile(data){
    return this.http.post(this.url+'updateprofile',data,{headers:this.reqheadermain});
  }

  removeprofilepicture(){
    return this.http.post(this.url+'updateprofilepicture',{headers:this.reqheadermain});
  }

  updateprofilepicture(data){
    return this.http.post(this.url+'updateprofilepicture',data,{headers:this.reqheadermain});
  }

  getmasterboard(){
    return this.http.get(this.url+'getmasterboard',{headers:this.reqheadermain});
  }

  getmastergrade(){
    return this.http.get(this.url+'getmastergrade',{headers:this.reqheadermain});
  }

  getSchoolAdminRoles(){
 return this.http.get(this.url+'getschooladminroles',{headers:this.reqheadermain});
  }

  getsubjectcategories(){
    return this.http.get(this.url+'getsubjectcategories',{headers:this.reqheadermain});
  }

  bulk(){
    return this.http.get(this.url+'downloadexcel',{headers:this.reqheadermain});
  } 
  
  deownloadexcelusers(){
    return this.http.get(this.url + 'downloadexcelusers', {headers:this.reqheadermain});
  }

  addbulkuploaduser(data){
    return this.http.post(this.url + 'uploadexcelusers', data,{headers:this.reqheadermain});
  }

  downloadassignteachers(){
    return this.http.get(this.url + 'downloadassignteachers', {headers:this.reqheadermain});
  }

  addbulkuploadassignteacher(data){
    return this.http.post(this.url + 'uploadassignteachers1', data,{headers:this.reqheadermain});
  }

  downloadAssignStudents(){
    return this.http.get(this.url+'downloadassignstudents', {headers: this.reqheadermain});
  }

  addBulkuploadAssignStudent(value){
    return this.http.post(this.url+'uploadassignstudents',value,{headers: this.reqheadermain});
  }
  getbulkuploadroles(){

    return this.http.get(this.url+'getbulkuploadroles',{headers:this.reqheadermain});
  }

  checkprincipal(value){ 
    return this.http.post(this.url+'checkprincipal',value,{headers:this.reqheadermain});
  }

  checkeditprinci(value){
    return this.http.post(this.url+'checkeditprinci',value,{headers:this.reqheadermain});
  }
 
  checkparentscorm() {
    return this.http.post(this.url +'checkparent', {headers:this.reqheadermain} );
  }

  submitpwdscorm(value) {
    var data = { password: value.password };
    return this.http.post(this.url +'resetpasswordparent', data, {headers:this.reqheadermain} );
  }

  loginRole() {
    return this.http.get(this.url + 'beforeloginrole ', { headers:this.reqheadermain } );
  }

  loginSchool() {
    return this.http.get(this.url + 'beforeloginschoolWeb ', { headers:this.reqheadermain });
  }

  registerdsash(value){
    var data = {days:value};
    return this.http.post(this.url+'filterusercnt',data,{headers:this.reqheadermain});
  }

  
  purchasedata(value){
    var data = {days:value};
    return this.http.post(this.url+'filterpurchasecount',data,{headers:this.reqheadermain});
  }

  partnerdata(value){
    var data = {days:value};
    return this.http.post(this.url+'filterpartnercnt',data,{headers:this.reqheadermain});
  }
  freedata(value){
    var data = {days:value};
    return this.http.post(this.url+'filterfreeSubcount',data,{headers:this.reqheadermain});
  }
  revenuedata(value){
    var data = {days:value};
    return this.http.post(this.url+'revenue_analysis',data,{headers:this.reqheadermain});
  }

  registerdata(value){
    var data = {days:value};
    return this.http.post(this.url+'filterusercnt',data,{headers:this.reqheadermain});
  }
  filterpurchasecount
  purchasegrapdata(value){
    var data = {days:value};
    return this.http.post(this.url+'filterpurchasecount',data,{headers:this.reqheadermain});
  }
  freeegrapdata(value){
    var data = {days:value};
    return this.http.post(this.url+'filterfreeSubcount',data,{headers:this.reqheadermain});
  }

  partnergrapdata(value){
    var data = {days:value};
    return this.http.post(this.url+'filterpartnercnt',data,{headers:this.reqheadermain});
  }
  PartnerRegistrationData(value){
    var data = {days:value};
    return this.http.post(this.url+'PartnerRegistrationsCount',data,{headers:this.reqheadermain});
  }
  Partnercouponcodedata(value){
    var data = {days:value};
    return this.http.post(this.url+'Partnercouponcodedata',data,{headers:this.reqheadermain});
  }
  ReferralFeesEarned(value){
    var data = {days:value};
    return this.http.post(this.url+'getreferraldatachart',data,{headers:this.reqheadermain});
  }
  CheckStatus(){
   
    return this.http.get(this.url+'check_partner',{headers:this.reqheadermain});
  }
  downloadSampleExcel(){
    return this.http.get(this.url+'downloadStudentSampleExcel',{headers:this.reqheadermain});
  }
  addBulkuploadStudent(value){
    return this.http.post(this.url+'importStudentData',value,{headers:this.reqheadermain})
  }

 
 

  GetBoard(){
    return this.http.get(this.url+'getmasterboard',{headers:this.reqheadermain});
  }
  GetGrade(value){
    var data ={board_id:value};
    return this.http.post(this.url+'checkgradelist',data,{headers:this.reqheadermain});
  }
  CreateSingleUser(value){
   
    return this.http.post(this.url+'singleuserregistration',value,{headers:this.reqheadermain});
  }
  Userlist(offset, limit, search, orderdir, orderby) {
    var data = { offset: offset, limit: limit, search: search, orderdir: orderdir, orderby: orderby };
    return this.http.post(this.url+'getuserlist', data, { headers: this.reqheadermain });
  }

  activeInactiveUser(id, status) {
    var data = { id: id, status: status };
    return this.http.post(this.url + 'activePartnersubscription', data, { headers: this.reqheadermain });
  }
}
